* {
  padding: 0;
  margin: 0;
}

:root {
  --main-color: darkblue; /*#2952a3*/
  --hover-color: rgb(53, 53, 209);
  --error-color: rgb(219, 27, 27);
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

.four-of-four-footer-block {
  margin-top: 10px;
}

.page-four-of-four-content {
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: center;
  background-color: #fcfcfc;
}

.page-four-of-four-content-section {
  width: 350px;
  padding: 50px 75px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.75);
  margin-top: 150px;
  border-radius: 5px;
}

.send-message-button,
.back-button {
  padding: 10px;
  background-color: var(--main-color);
  border: none;
  outline: none;
  color: white;
  font-size: 15px;
  border-radius: 2px;
  transition: background-color 500ms ease;
  cursor: pointer;
}

.back-button {
  width: 100px;
}

.send-message-button:hover,
.back-button:hover {
  background-color: var(--hover-color);
}

.label {
  line-height: 50px;
  text-align: center;
  font-size: 60px;
  color: var(--main-color);
  font-weight: 900;
  margin-bottom: 15px;
}

.text {
  text-align: center;
  font-size: 16px;
  color: var(--main-color);
}

:target {
  scroll-margin-top: 5rem;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 75px;
  color: var(--main-color);
  box-shadow: var(--shd-scrl,0 0 5px rgba(0,0,0,.5));
  z-index: 100;
  position: fixed;
  width: 100%;
  background-color: white;
}

.logo {
  line-height: 75px;
  float: left;
  font-size: 24px;
  margin-left: 40px;
}

header .nav-links {
  float: right;
  list-style: none;
  font-size: 14px;
}

header .nav-links {
  line-height: 75px;
  padding-right: 25px;
}

header .nav-links-laptop li {
  display: inline;
  margin-left: 25px;
  cursor: pointer;
  transition: opacity 500ms ease;
  transition: color 500ms ease;
}

.select-lang-button {
  display: inline;
  margin-bottom: -5px;
  cursor: pointer;
}

.lang-select {
  background-color: white;
  float: left;
  position: absolute;
  right: 0px;
  top: 75px;
  animation: fadeIn 0.2s ease-in-out;
  box-shadow: var(--shd-scrl,0 3px 4px rgba(0,0,0,.5));
}

.lang-option {
  height: fit-content;
  width: 125px;
  padding: 5px;
  float: left;
  clear: both;
  cursor: pointer;
  transition: background-color 500ms ease;
}

.lang-option img {
  float: left;
}

.project-link {
  font-weight: 600;
}

.lang-label {
  float: left;
  line-height: 18px;
  margin-left: 5px;
}

.lang-option:hover {
  background-color: #f2f2f2;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

header .nav-links li a,
.social-link {
  text-decoration: none;
  color: inherit;
}

.project-title {
  color: var(--main-color);
  margin-top: 10px;
  font-size: 18px;
}

.project-content {
  min-height: 220px;
}

.project-description {
  text-align: start;
  text-indent: 10px;
}

header .nav-links li:hover {
  color: var(--hover-color);
}

.select-lang {
  color: inherit;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-family: inherit;
  font-size: inherit;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 20px;
}

.wrapper {
  padding-top: 75px;
}

.presentation {
  background: url('./images/home-image.jpg') no-repeat;
  width: 100%;
  height: calc(100vh - 75px);
  background-size: cover;
  background-position: center center;
  background-blend-mode: multiply;
  background-color: lightslategray; /*dimgrey, darkgray, lightslategray, slategray*/
  position: relative;
}

.presentation-text {
  position: absolute;
  top: 25%;
  left: 50px;
  font-size: 36px;
  font-weight: 400;
  color: white;
}

.services-section {
  width: 90%;
  background-color: white;
  color: var(--main-color);
  padding: 60px 5%;
}

.services-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
}

.services-list {
  margin: 40px auto;
}

.projects-list {
  margin: 10px auto;
  padding-bottom: 50px;
  width: 850px;
}

.services-list {
  width: 750px;
}

.services-list,
.projects-list {
  display: flex;
  flex-direction: column;
  height: fit-content !important;
}

.service-header {
  display: flex;
  justify-content: center;
}

.service-block {
  padding-top: 10px;
  padding-bottom: 25px;
  width: 100%;
  float: left;
  text-align: center;
}

.service-icon {
  font-size: 24px;
  margin-right: 5px;
}

.projects-title {
  margin-top: 25px;
  font-size: 20px;
  color: var(--main-color);
}

.project-content {
  display: flex;
  color: var(--main-color);
}

.service-description,
.project-content {
  border-radius: 10px;
  border: 2px dashed silver;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
}

.service-description p {
  text-indent: 20px;
}

.horizontal-line {
  border: none;
  background-color: silver;
  height: 1px;
  width: 260px;
  margin: auto;
  margin-top: 10px;
}

.about-section,
.about-section-ceo {
  height: fit-content;
  width: 80%;
  padding: 50px 10% 80px;
}

.about-section {
  color: white;
  background-color: var(--main-color);
}

.about-section-ceo {
  background-color: white;
  color: var(--main-color);
}

.about-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 35px;
}

.about-description {
  text-align: center;
  margin-top: 20px;
}

.contacts-section {
  width: calc(100% - 200px);
  padding: 50px 100px;
}

.contacts-title {
  color: var(--main-color);
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 35px;
}

.contacts-content {
  display: flex;
  flex-direction: row;
}

.contacts-block {
  width: calc(100% - 400px);
}

.contact-us {
  color: var(--main-color);
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
}

.address-section {
  color: var(--main-color);
  width: 400px;
  text-align: start;
}

.social-link-icon {
  font-size: 24px;
  cursor: pointer;
  transition: color 500ms ease;
}

.social-link:last-child {
  margin-left: 5px;
}

.social-link-icon:hover {
  color: var(--hover-color);/*mediumblue;*/
}

.address,
.telephone-number {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 180px;
  font-size: 14px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.input-field {
  outline: none;
  padding: 10px;
  font-size: 16px;
  border: 2px solid var(--main-color);
  border-radius: 2px;
}

.input-field:hover {
  border-color: #333;
}

.error-text {
  text-align: start;
  height: 20px;
  width: 100%;
  font-size: 14px;
  font-family: Arial;
  padding-left: 3px;
  color: var(--error-color);
}

.error-input {
  border-color: var(--error-color);
}

.send-message-button {
  width: 200px;
}

.message-field {
  font-family: Arial;
  height: 200px;
  resize: none;
}

.send-message-button:hover {
  background-color: var(--hover-color);
}

.video-file {
  height: fit-content;
  width: 500px;
  height: 280px;
  border-radius: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12);
}

.fade-text {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
} 


section {
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  transition: opacity 700ms ease-out, transform 700ms ease-out;
}

.fade-right {
  transform: translateX(50%);
}

.fade-left {
  transform: translateX(-50%);
}

.fade-bottom {
  transform: translate(0, 50%);
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media(min-width: 1070px) {
  .address-title {
    display: block;
  }

  .services-section {
    height: fit-content;
  }
  
  .services-list,
  .projects-list {
    height: 200px;
  }
}

@media(max-width: 1070px) {
  .services-section {
    
  }
  
  .services-list,
  .projects-list {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 600px;
  }
  
  .service-block,
  .project-block {
    margin: auto !important;
    width: inherit;
    max-width: 300px;
  }

  .video-file {
    width: 280px;
  }

  .project-content {
    flex-direction: column;
  }

  .contacts-section {
    width: 80%;
    padding: 50px 10%;
  }

  .contacts-title {
    margin-bottom: 0px;
  }

  .contacts-content {
    flex-direction: column-reverse;
    height: fit-content;
  }

  .address-title {
    display: none;
  }

  .address, 
  .telephone-number {
    width: inherit;
  }

  .address-section {
    margin: auto;
    width: inherit;
    text-align: center;
  }

  .contacts-block {
    margin: 20px auto 0px;
    width: 80%;
  }

  .send-message-button {
    margin: auto;
  }
}

@media(min-width: 700px) {
  .mobile-menu-button {
    display: none;
  }

  .project-description {
    padding-left: 10px;
  }
}

@media(max-width: 700px) {
  .page-four-of-four-content-section {
    width: calc(90% - 60px);
    padding: 30px;
  }

  .nav-links-laptop {
    display: none;
  }

  .lang-select {
    left: 0px;
    top: 230px;
    border-top: 1px solid #f2f2f2;
  }

  .lang-option {
    width: calc(100% - 10px);
  }

  .nav-links-mobile {
    margin: 0;
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) -14px 14px 10px -10px;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
    animation: fadeIn 0.2s ease-in-out;
  }

  header .nav-links-mobile {
    top: 74px;
    line-height: 24px;
    padding-bottom: 10px;
    border-top: 1px solid silver;
  }
  
  header .nav-links-mobile li {
    padding: 10px 20px;
  }

  .mobile-menu-button {
    color: var(--main-color);
    display: block;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .presentation::after  {
    width: fit-content;
    max-width: 400px;
    font-size: 28px;
    left: 15px;
  }

  .contacts-block {
    width: 100%;
  }
}

@media(max-width: 500px) {
  .presentation-text {
    left: 18px;
    width: fit-content;
    font-size: 30px;
    max-width: 320px;
  }

  .message-field {
    height: 250px;
  }

  .service-header {
    flex-direction: column;
  }

  .service-icon {
    margin: auto;
  }

  .service-block,
  .project-block {
    padding-bottom: 15px;
  }

  .services-list,
  .projects-list {
    margin-top: 20px;
    height: fit-content;
  }
  
  .fade-right,
  .fade-left,
  .fade-bottom {
    transform: none;
  }
}